import './App.css';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CookieBanner from "./components/CookieBanner";
import {Route, Routes} from "react-router-dom";
import Homepage from "./pages/Homepage";
import PiccoliEserciziCommerciali from "./pages/PiccoliEserciziCommerciali";
import ImpiantiSportivi from "./pages/ImpiantiSportivi";
import AmministrazioniPubbliche from "./pages/AmministrazioniPubbliche";
import PiccoleEMicroimprese from "./pages/PiccoleEMicroimprese";
import ScrollToTop from "./components/ScrollToTop";
import {useEffect, useState} from "react";
import Contatti from "./pages/Contatti";


function App() {
    // const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 5000);
    // }, []);

    // if (isLoading) {
    //     Qui puoi mostrare un componente di caricamento
        // return <div className="loading-container">
        //     <div className="loading-logo"></div>
        // </div>
        //     ;
    // }
  return (
    <div>

        <Navbar/>
        <ScrollToTop />
        <Routes>
            <Route exact index path="/" element={
                <Homepage />
            }>
            </Route>
            <Route exact  path="/piccoli-esercizi-commerciali" element={
                <PiccoliEserciziCommerciali />
            }>
            </Route>
            <Route exact  path="/impianti-sportivi" element={
                <ImpiantiSportivi />
            }>
            </Route>
            <Route exact  path="/amministrazioni-pubbliche" element={
                <AmministrazioniPubbliche />
            }>
            </Route>
            <Route exact  path="/piccole-e-microimprese" element={
                <PiccoleEMicroimprese/>
            }>
            </Route>
            <Route exact  path="/contatti" element={
                <Contatti/>
            }>
            </Route>
        </Routes>
        <Footer/>
        <CookieBanner/>
    </div>
  );
}

export default App;
