import piccoliEsercizi from "../assets/sportivi.jpg";

const ImpiantiSportivi = () => {
    return(
        <div className="mb-10">
            <div className={'bannerInternal flex flex-wrap justify-center items-center relative mb-10'}>
                <h2 className={'text-white absolute lg:text-68px text-[50px] m-auto text-center font-bold z-20'}>
                    Impianti sportivi
                </h2>
                <div className={'bg-black absolute top-0 left-0 w-full h-full z-10 opacity-[0.2]'}></div>
                <img src={piccoliEsercizi} className={'w-full object-cover h-[350px]'} alt="piccoliEsercizi"/>

            </div>
            <div className={'text-container xl:px-40 px-10 '}>

                <p className={'mb-5'}>
                    Sviluppiamo la strategia migliore per digitalizzare il sistema di prenotazione del tuo impianto sportivo ed automatizzare il controllo degli accessi. Grazie al nostro sistema riuscirai a controllare gli accessi al tuo impianto semplificando il sistema di prenotazione.                 </p>
                <p className={'font-bold mb-2'}>Perché sceglierci</p>
                <ul>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Soluzione personalizzata</span>  studiata e sviluppata appositamente sulla base delle tue esigenze grazie al nostro percorso consulenziale che si realizza in 4 semplici passi;
                    </li>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Semplifica la tua attività</span> grazie all’utilizzo di sistemi automatici e digitali di prenotazione e gestione degli accessi al tuo impianto;
                    </li>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Risparmia tempo</span> utilizzando un sistema di iscrizione e caricamento dati online direttamente dal vostro sito internet.
                    </li>
                </ul>
                <br/>
                <p className={'font-bold'}>Principali aree d’intervento</p>
                Sistemi di prenotazione automatica: sviluppiamo sistemi di prenotazione automatica del tuo impianto sportivo, online o tramite app. Il tuo cliente avrà la possibilità di prenotare e pagare il tuo impianto direttamente dal tuo sito internet o da un’apposita app ricevendo direttamente sul suo smartphone un QR code per l’accesso.
                <br/>
                Gestione degli accessi: realizziamo sistemi automatici di verifica delle credenziali di accesso al tuo impianto. In questo modo potrà controllare in maniera automatica l’effettiva titolarità ad accedere al tuo impianto mediante una semplice app o ad un apposito lettore. Avrai inoltre a sempre a disposizione una reportistica dettagliata degli ingressi al tuo impianto.
                <br/>

            </div>
        </div>
    )
}

export default ImpiantiSportivi;