import piccoliEsercizi from "../assets/aziende.jpg";

const PiccoleEMicroimprese = () => {

    return(
        <div className="mb-10">
            <div className={'bannerInternal flex flex-wrap justify-center items-center relative mb-10'}>
                <h2 className={'text-white absolute lg:text-68px text-[50px] m-auto text-center font-bold z-20'}>
                    Piccole e microimprese
                </h2>
                <div className={'bg-black absolute top-0 left-0 w-full h-full z-10 opacity-[0.2]'}></div>
                <img src={piccoliEsercizi} className={'w-full object-cover h-[350px]'} alt="piccoliEsercizi"/>

            </div>
            <div className={'text-container xl:px-40 px-10 '}>

                <p className={'mb-5'}>
                    Aiutiamo piccole e micro imprese ad implementare strumenti e tecnologie digitali che possano rendere più efficienti i processi e i flussi aziendali.
                </p>
                <p className={'font-bold mb-2'}>Perché sceglierci</p>
                <ul>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Soluzione personalizzata</span> studiata e sviluppata appositamente sulla base delle tue esigenze grazie al nostro percorso consulenziale che si realizza in 4 semplici passi;
                    </li>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Semplifica la tua attività e taglia tempi inutili</span> attraverso l’utilizzo di semplici fogli di calcolo o app per semplificare la tua gestione in modo da avere sempre tutte le informazioni necessarie a portata di mano.
                    </li>
                </ul>
                <br/>
                <p className={'font-bold'}>Principali aree d’intervento</p> <br></br>
                Efficientamento dei processi aziendali: Offriamo soluzioni digitali semplici per automatizzare operazioni di processo come la compilazione dei registri di manutenzione o la gestione delle commesse. Ci occupiamo, inoltre, dell’analisi e dell'ottimizzazione dei processi, della gestione progettuale e della realizzazione di analisi di mercato.
            </div>
        </div>
    )
}

export default PiccoleEMicroimprese;