import React, { Component } from "react";
import Slider from "react-slick";
import testimage from '../assets/pexels-fauxels-3183197.jpg'
import ratioWhite from '../assets/ratio_scritta_white.png'
import "./Slideshow.css";
const Slideshow = () => {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true
        };
        return (
            <div className={`slideshow`}>
                <Slider {...settings}>
                    <div className={'relative'}>
                        <div className="content p-10 md:p-0">
                            {/*<h3 className={'slideshow-title'}>Lorem Ipsum</h3>*/}
                            <img src={ratioWhite} className={'image-ratio w-[500px]'}/>
                             <p className={'text-center text-white z-10 testo-descrittivo'}>Ottieni il massimo dalla tua attività sfruttando i vantaggi offerti dalla digitalizzazione</p>
                        </div>
                        <div className="image">
                            <div className="sfondo-viola"></div>
                            <div className="sfondo-giallo"></div>
                            <img src={testimage} className={'w-full object-cover'} alt="testimage"/>
                        </div>
                    </div>
                </Slider>
            </div>
        );
}

export default Slideshow;