import React, { useState } from 'react';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    const handleAccept = () => {
        // Imposta il consenso dell'utente su 'true'
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    const handleDecline = () => {
        // Imposta il consenso dell'utente su 'false'
        localStorage.setItem('cookieConsent', 'false');
        setShowBanner(false);
    };

    if (!showBanner) {
        return null; // Nascondi il banner se non è necessario visualizzarlo
    }

    return (
        <div className={'w-full flex items-center justify-center text-center'}>
            <div className="fixed bottom-0 rounded-2xl bg-white shadow-2xl text-black flex justify-between items-center p-10">
                <p className="mr-10 text-left">
                    Questo sito utilizza solo cookie tecnici obbligatori per garantire il corretto funzionamento del sito.
                </p>
                <div className="space-x-2">
                    <button
                        className="bg-rs-purple text-white py-2 px-4 rounded mb-5 w-[100px]"
                        onClick={handleAccept}
                    >
                        Accetta
                    </button>
                    <button
                        className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded mb-5 w-[100px]"
                        onClick={handleDecline}
                    >
                        Rifiuta
                    </button>
                </div>
            </div>
        </div>

    );
};

export default CookieBanner;
