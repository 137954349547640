import icona1 from '../assets/market.svg'
import icona2 from '../assets/track-and-field.svg'
import icona3 from '../assets/municipal.svg'
import icona4 from '../assets/production.svg'

import './Cosafacciamo.css'
import {Link} from "react-router-dom";
const Cosafacciamo = () => {
    return (
        <div id="cosafacciamo" className="xl:px-40 px-10">
            <div className="pb-5 mt-5">
                <div className="col-12 mb-10">
                    <h1 className="font-bold mb-10 lg:text-68px text-[50px] uppercase text-rs-yellow">Cosa Facciamo</h1>
                    <p className="lg:text-22px text-[18px] leading-40px">
                        Sviluppiamo soluzioni digitali studiate su misura a servizio di gestori di impianti e società sportive, piccoli esercizi commerciali, piccole e microimprese, comuni e enti locali.
                    </p>
                </div>
                <h6 className="text-rs-yellow mb-4 mt-5 lg:text-38px text-[30px] font-semibold">A chi ci rivolgiamo</h6>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 text-center">
                    <div className="custom-shadow rounded-[10px] relative pb-2 p-5 text-center h-[310px] overflow-hidden">
                        <Link to={'/piccoli-esercizi-commerciali'}>

                        <img className="mt-3 mb-5 m-auto w-32" src={icona1} alt="icona1" />
                        <p className="text-rs-purple mb-4 text-26px font-semibold">Piccoli esercizi commerciali</p>
                        <div className={'testohover'}>
                            Digitalizzazione di operazioni gestionali – strategie di vendita multicanale – archiviazione automatica digitale – Marketing e comunicazione
                        </div>
                        <div className="border-card bg-rs-yellow absolute bottom-0 left-0 w-full h-2 border-b-s-r-10 border-b-e-r-10"></div>
                        </Link>
                    </div>
                    <div className="custom-shadow rounded-[10px] relative pb-2 p-5 text-center h-[310px] overflow-hidden">
                        <Link to={'/impianti-sportivi'}>
                        <img className="mt-3 mb-5 m-auto w-32" src={icona2} alt="icona2" />
                        <p className="text-rs-purple mb-4 text-26px font-semibold">Impianti sportivi</p>
                        <div className={'testohover'}>
                            Sistemi di prenotazione automatici – gestione automatica degli accessi
                        </div>
                        <div className="border-card bg-rs-yellow absolute bottom-0 left-0 w-full h-2 border-b-s-r-10 border-b-e-r-10"></div>
                        </Link>
                    </div>
                    <div className="custom-shadow rounded-[10px] relative pb-2 p-5 text-center h-[310px] overflow-hidden">
                        <Link to={'/amministrazioni-pubbliche'}>
                        <img className="mt-3 mb-5 m-auto w-32" src={icona3} alt="icona3" />
                        <p className="text-rs-purple mb-4 text-26px font-semibold">Amministrazioni pubbliche</p>
                        <div className={'testohover'}>
                            Strategie di valorizzazione dei propri esercizi commerciali – marketplace locale – digitalizzazione dei propri servizi (sistemi di prenotazione degli spazi, sistemi di archiviazione) - attività di formazione
                        </div>
                        <div className="border-card bg-rs-yellow absolute bottom-0 left-0 w-full h-2 border-b-s-r-10 border-b-e-r-10"></div>
                        </Link>
                    </div>
                    <div className="custom-shadow rounded-[10px] relative pb-2 p-5 text-center h-[310px] overflow-hidden">
                        <Link to={'/piccole-e-microimprese'}>
                        <img className="mt-3 mb-5 m-auto w-32" src={icona4} alt="icona4" />
                        <p className="text-rs-purple mb-4 text-26px font-semibold">Piccole aziende e microimprese</p>
                        <div className={'testohover'}>
                            Automatizzazione delle operazioni di processo - analisi e ottimizzazione dei processi - gestione progettuale - analisi di mercato
                        </div>
                        <div className="border-card bg-rs-yellow absolute bottom-0 left-0 w-full h-2 border-b-s-r-10 border-b-e-r-10"></div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Cosafacciamo