import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

class SmoothScrollLink extends React.Component {
    handleClick = () => {
        const { to } = this.props;
        animateScroll.scrollToTop();
        setTimeout(() => {
            const element = document.getElementById(to);
            if (element) {
                animateScroll.scrollTo(element.offsetTop, {
                    duration: 500,
                    smooth: true,
                });
            }
        }, 500);
    };

    render() {
        const { to, children } = this.props;
        return (
            <RouterLink to="/" onClick={this.handleClick}
                        className={this.props.className}
            >
                {children}
            </RouterLink>
        );
    }
}

export default SmoothScrollLink;
