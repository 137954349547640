import React, { useState } from 'react';

import './Comelavoriamo.css'
import rightArrow from '../assets/right-arrow.png'

const Comelavoriamo = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [isHovered, setIsHovered] = useState([false, false, false, false]);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleMouseEnter = (index) => {
        setIsHovered(isHovered.map((val, i) => (i === index ? true : val)));
    };

    const handleMouseLeave = (index) => {
        setIsHovered(isHovered.map((val, i) => (i === index ? false : val)));
    };

    return (
        <div id="comelavoriamo" className="mt-20">
            <div className="col-12 mb-10 xl:px-40 px-10 ">
                <h1 className="font-bold mb-10 lg:text-68px text-[50px] uppercase text-rs-yellow">Come lavoriamo</h1>
                <p className="lg:text-22px text-[18px] leading-40px">
                    Il nostro valore aggiunto è rappresentato dal processo consulenziale personalizzato, dalla capacità di elaborare soluzioni semplici ed intuitive basate sulle esigenze e sulle competenze del cliente che dovrà trovarsi poi a doverle utilizzare.
                </p>
                <h1 className="text-rs-yellow text-4xl font-semibold mb-20 w-full mt-20">Per questo motivo offriamo un percorso consulenziale sartoriale che si articola in 4 passi:</h1>
            </div>
        <div className="flex items-start xl:px-40 px-10 bg-purple-custom pt-20 pb-20 mt-20">
            <div className="w-full ">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/2">
                        <div className="flex flex-col space-y-2">
                            <button
                                className={`flex flex-wrap items-center px-0 text-rs-purple text-start lg:text-38px text-[30px]  ${
                                    activeTab === 0 ? 'font-semibold underline' : ''
                                }`}
                                onClick={() => handleTabClick(0)}
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
                            >
                                Presentati
                                <img
                                    src={rightArrow}
                                    alt=""
                                    className={`w-8 ml-3 transition-all duration-200 ${
                    isHovered[0] || activeTab === 0 ? 'opacity-100' : 'opacity-0'                                    }`}
                                />
                            </button>
                            <button
                                className={`flex flex-wrap items-center px-0 text-rs-purple text-start lg:text-38px text-[30px]  ${
                                    activeTab === 1 ? 'font-semibold underline' : ''
                                }`}
                                onClick={() => handleTabClick(1)}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
                            >
                                Creiamo il tuo percorso
                                <img
                                    src={rightArrow}
                                    alt=""
                                    className={`w-8 ml-3 transition-all duration-200 ${
                    isHovered[1] || activeTab === 1 ? 'opacity-100' : 'opacity-0'                                    }`}
                                />
                            </button>
                            <button
                                className={`flex flex-wrap items-center px-0 text-rs-purple text-start lg:text-38px text-[30px]  ${
                                    activeTab === 2 ? 'font-semibold underline' : ''
                                }`}
                                onClick={() => handleTabClick(2)}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
                            >
                                Proviamo se funziona
                                <img
                                    src={rightArrow}
                                    alt=""
                                    className={`w-8 ml-3 transition-all duration-200 ${
                    isHovered[2] || activeTab === 2 ? 'opacity-100' : 'opacity-0'                                    }`}
                                />
                            </button>
                            <button
                                className={`flex flex-wrap items-center px-0 text-rs-purple text-start lg:text-38px text-[30px]  ${
                                    activeTab === 3 ? 'font-semibold underline' : ''
                                }`}
                                onClick={() => handleTabClick(3)}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave(3)}
                            >
                                Si comincia
                                <img
                                    src={rightArrow}
                                    alt=""
                                    className={`w-8 ml-3 transition-all duration-200 ${
                    isHovered[3] || activeTab === 3 ? 'opacity-100' : 'opacity-0'                                    }`}
                                />
                            </button>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:px-24 pb-24">
                        <div className="flex items-center h-full">
                            <div>
                                <div
                                    className={`leading-52px lg:text-22px text-[18px] text-white ${
                                        activeTab === 0 ? 'block' : 'hidden'
                                    }`}
                                >
                                    Lascia i tuoi contatti nell’apposito link specificando le tue esigenze e sarai contattato entro 48 ore per fissare un incontro gratuito, online o in presenza, con un nostro esperto per capire come possiamo aiutarti ad affrontare le tue sfide quotidiane.
                                </div>
                                <div
                                    className={`leading-52px lg:text-22px text-[18px] text-white ${
                                        activeTab === 1 ? 'block' : 'hidden'
                                    }`}
                                >
                                    Ti verrà inviata una proposta personalizzata in base alle esigenze espresse ed alla valutazione effettuata da parte del nostro esperto e sarai guidato nella compilazione di una check-list personalizzata con richiesta di dati ed informazioni che ci saranno necessarie per sviluppare la soluzione concordata.
                                </div>
                                <div
                                    className={`leading-52px lg:text-22px text-[18px] text-white ${
                                        activeTab === 2 ? 'block' : 'hidden'
                                    }`}
                                >
                                    Verrà realizzata una prima versione della soluzione concordata con una descrizione puntuale dei pro e dei contro di quanto realizzato. I risultati della soluzione beta ed una serie di possibili alternative ti verranno presentati al fine di effettuare la scelta migliore per la tua attività.                                </div>
                                <div
                                    className={`leading-52px lg:text-22px text-[18px] text-white ${
                                        activeTab === 3 ? 'block' : 'hidden'
                                    }`}
                                >
                                    Una volta realizzata la soluzione definitiva sarai assistito nella fase di start-up della tua proposta personalizzata all’interno della tua attività commerciale
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Comelavoriamo;
