import ratioWhite from '../assets/ratio_scritta_white.png'
import SmoothScrollLink from "./SmoothScrollLink";
import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <footer className="bg-rs-purple">
            <div className="lg:pe-28 lg:ps-28 lg:mt-28 lg:pt-28 lg:pb-28 p-10">
                <img src={ratioWhite} className={'w-56 -ml-5 mb-5'}/>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-5">
                    {/*<div className="col-span-1">*/}
                    {/*    <h1 className="text-white text-2xl font-semibold mb-4">*/}
                    {/*        <a href="#" className="text-decoration-none">*/}
                    {/*            Come lavoriamo*/}
                    {/*        </a>*/}
                    {/*    </h1>*/}
                    {/*    <h1 className="text-white text-2xl font-semibold mb-4">*/}
                    {/*        <a href="#" className="text-decoration-none">*/}
                    {/*            News*/}
                    {/*        </a>*/}
                    {/*    </h1>*/}
                    {/*    <h1 className="text-white text-2xl font-semibold mb-4">*/}
                    {/*        <a href="#" className="text-decoration-none">*/}
                    {/*            Contatti*/}
                    {/*        </a>*/}
                    {/*    </h1>*/}
                    {/*</div>*/}
                    <div className="col-span-1">
                        <Link to={'/contatti'} className="text-white text-xl mb-2 block font-normal">

                            <h1 className="text-white text-2xl font-semibold mb-4 cursor-pointer hover:underline">
                                Contatti
                            </h1>
                            <p className={'text-white mb-2 text-[17px]'}>
                            25045 Castegnato (BS) - Via Marconi 2/B
                            </p>
                            <p className={'text-white mb-2 text-[17px]'}>
                                C.F./P.IVA 04456670985
                            </p>
                            <p className={'text-white mb-2 text-[17px]'}>
                                PEC: ratiosolutions@pec.ratiosolutions.net
                            </p>

                        </Link>
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    Perché sceglierci*/}
                        {/*</a>*/}
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    Principali aree d'intervento*/}
                        {/*</a>*/}
                    </div>

                    <div className="col-span-1">

                        <SmoothScrollLink smooth={true} duration={500} to="chisiamo">

                        <h1 className="text-white text-2xl font-semibold mb-4 cursor-pointer hover:underline">Chi siamo</h1>
                        </SmoothScrollLink>
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    La nostra visione*/}
                        {/*</a>*/}
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    La nostra missione*/}
                        {/*</a>*/}
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    Team*/}
                        {/*</a>*/}
                    </div>
                    <div className="col-span-1">
                        <SmoothScrollLink smooth={true} duration={500} to="cosafacciamo">
                        <h1 className="text-white text-2xl font-semibold mb-4 cursor-pointer hover:underline">Cosa Facciamo</h1>
                        </SmoothScrollLink>
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    A chi ci rivolgiamo*/}
                        {/*</a>*/}
                        <Link to="/piccoli-esercizi-commerciali" className="text-white text-xl mb-2 block font-normal">
                            Piccoli esercizi commerciali
                        </Link>
                        <Link to="/impianti-sportivi" className="text-white text-xl mb-2 block font-normal">
                            Impianti Sportivi
                        </Link>
                        <Link to="/amministrazioni-pubbliche" className="text-white text-xl mb-2 block font-normal">
                            Amministrazioni pubbliche
                        </Link>
                        <Link to="/piccole-e-microimprese" className="text-white text-xl mb-2 block font-normal">
                            Piccole Aziende e microimprese
                        </Link>
                    </div>
                    <div className="col-span-1">
                        <SmoothScrollLink smooth={true} duration={500} to="comelavoriamo">
                        <h1 className="text-white text-2xl font-semibold mb-4 cursor-pointer hover:underline">
                            Come lavoriamo
                        </h1>
                        </SmoothScrollLink>
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    Perché sceglierci*/}
                        {/*</a>*/}
                        {/*<a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                        {/*    Principali aree d'intervento*/}
                        {/*</a>*/}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {/*    <div className="col-span-1"></div>*/}
                {/*    <div className="col-span-1">*/}
                {/*        <h1 className="text-white text-2xl font-semibold mb-4">Aziende</h1>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Perché sceglierci*/}
                {/*        </a>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Principali aree d'intervento*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="col-span-1">*/}
                {/*        <h1 className="text-white text-2xl font-semibold mb-4">*/}
                {/*            Gestori di impianti e di società sportive*/}
                {/*        </h1>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Perché sceglierci*/}
                {/*        </a>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Principali aree d'intervento*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="col-span-1">*/}
                {/*        <h1 className="text-white text-2xl font-semibold mb-4">*/}
                {/*            Amministrazioni pubbliche*/}
                {/*        </h1>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Perché sceglierci*/}
                {/*        </a>*/}
                {/*        <a href="#" className="text-white text-xl mb-2 block font-normal">*/}
                {/*            Principali aree d'intervento*/}
                {/*        </a>*/}
                {/*    </div>*/}

                </div>
                <div className="flex flex-wrap border-t-2 w-full pb-5 pt-5 mt-20">
                    <a href="#" className="text-white text-xl mb-2 block font-normal mr-5 border-r-2 pr-5 cursor-pointer hover:underline">
                        Privacy Policy
                    </a>
                    <a href="#" className="text-white text-xl mb-2 block font-normal cursor-pointer hover:underline">
                        Cookie Policy
                    </a>
                </div>

            </div>
        </footer>

    )
}

export default Footer