import React from 'react';
import logo from '../assets/logo.png'
import {Link} from "react-router-dom";
import SmoothScrollLink from "./SmoothScrollLink";

const Navbar = () => {
    return (
        <nav className="bg-white shadow">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center h-32">
                    <div className="flex items-center justify-between w-full">
                        <div className="flex-shrink-0">
                            <Link to={"/"}>
                                <img className="w-40" src={logo} alt="Logo" />

                            </Link>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <Link to="/" className="text-rs-purple hover:text-rs-purple px-3 py-2 rounded-md font-medium hover:underline cursor-pointer">Home</Link>
                                <SmoothScrollLink smooth={true} duration={500} to="chisiamo" className="cursor-pointer text-rs-purple hover:text-rs-purple px-3 py-2 rounded-md font-medium hover:underline cursor-pointer">Chi siamo</SmoothScrollLink>
                                <SmoothScrollLink smooth={true} duration={500} to="cosafacciamo" className="text-rs-purple hover:text-rs-purple px-3 py-2 rounded-md font-medium hover:underline cursor-pointer">Cosa facciamo</SmoothScrollLink>
                                <SmoothScrollLink smooth={true} duration={500} to="comelavoriamo" className="text-rs-purple hover:text-rs-purple px-3 py-2 rounded-md font-medium hover:underline cursor-pointer">Come lavoriamo</SmoothScrollLink>
                                <Link to="/contatti" className="text-rs-purple hover:text-rs-purple px-3 py-2 rounded-md font-medium hover:underline cursor-pointer">Contatti</Link>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            {/* Add your additional navbar items here */}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
