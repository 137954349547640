import testimg from '../assets/testimg.jpg';
import React from "react";

import mission from '../assets/Mission.png';
import vision from '../assets/Vision.png';
import team from '../assets/Team.png';
const Chisiamo = () => {
    return (

        <div id="chisiamo" className={'xl:px-40 px-10'}>
            <div className="col-12 mb-20">
                <h1 className="font-bold mb-10 lg:text-68px text-[50px] uppercase text-rs-yellow">Chi siamo</h1>
                <p className="lg:text-22px text-[18px] leading-40px">
                    Siamo una società di consulenza che si occupa di sviluppare soluzioni digitali sartoriali studiate su misura del cliente a servizio di gestori di impianti e società sportive, piccoli esercizi commerciali, piccole e microimprese, comuni e enti locali.                        </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                    <h6 className="text-rs-purple mb-3 lg:text-38px text-[30px] font-semibold">La nostra Mission</h6>
                    <p className="lg:text-22px text-[18px] leading-40px">
                        Siamo convinti che il futuro appartenga alle aziende ed ai lavoratori che sapranno adattarsi ai cambiamenti. Crediamo che i vantaggi offerti dalla transizione digitale rappresentino un’importante opportunità per rendere il tuo business più “flessibile” ed in grado di adattarsi ai cambiamenti delle abitudini e del contesto in cui operi.                     </p>
                </div>
                <div>
                    <img className="w-full" src={mission} alt="testimg" />
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className={'lg:block hidden'}>
                    <img className="w-full" src={vision} alt="testimg" />
                </div>
                <div>
                    <h6 className="text-rs-purple mb-3 lg:text-38px text-[30px] font-semibold">La nostra Vision</h6>
                    <p className="lg:text-22px text-[18px] leading-40px">
                        Grazie al nostro percorso consulenziale vogliamo aiutarti a digitalizzare la tua attività automatizzando operazioni quotidiane e ripetitive grazie all’utilizzo di semplici app o fogli di calcolo. Offriamo soluzioni sviluppate su misura per te con l’obiettivo di aiutarti ad ottenere il massimo dalla tua attività combinando i vantaggi che gli strumenti digitali offrono con le peculiarità ed i punti di forza del tuo servizio.
                    </p>
                </div>
                <div className={'lg:hidden block '}>
                    <img className="w-full" src={vision} alt="testimg" />
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                    <h6 className="text-rs-purple mb-3 lg:text-38px text-[30px] font-semibold">Team</h6>
                    <p className="lg:text-22px text-[18px] leading-40px">
                        Ratio Solutions mette a tua disposizione un gruppo di lavoro formato da professionisti giovani, qualificati che operano in diversi settori dell’ingegneria, dell’informatica e della comunicazione.
                    </p>
                </div>
                <div>
                    <img className="w-full" src={team} alt="testimg" />
                </div>
            </div>
        </div>

    );
}

export default Chisiamo;