import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import {useEffect} from "react";

const Contatti = () => {
    // useEffect(() => {
    //     L.Marker.prototype.options.icon = L.icon({
    //         iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    //         shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
    //         iconSize: [25, 41],
    //         iconAnchor: [12, 41],
    //         popupAnchor: [1, -34],
    //         tooltipAnchor: [16, -28],
    //         shadowSize: [41, 41],
    //     });
    // }, []);
    return (
        <section id={'contatti'} className={'bg-rs-gray'}>
            <div  className="xl:px-40 px-10">
                <h1 className={'text-rs-purple mt-5 text-5xl font-bold mb-10'}>Contatti</h1>
                <p className={'text-rs-purple mt-5 text-2xl font-semibold mb-5'}>Dove trovarci:</p>
                <p className={'text-rs-purple mb-2 text-[17px]'}>
                    25045 Castegnato (BS) - Via Marconi 2/B
                </p>

                <MapContainer center={[45.56484931597363, 10.115484905363783]} zoom={13} style={{ height: '50vh', width: '100%', borderRadius: '20px', overflow:'hidden', marginBottom: '20px' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                    />
                    <Marker position={[45.56484931597363, 10.115484905363783]} />
                </MapContainer>
            </div>
        </section>


    )

}

export default Contatti;