import Slideshow from "../components/Slideshow";
import Chisiamo from "../components/Chisiamo";
import Cosafacciamo from "../components/Cosafacciamo";
import Comelavoriamo from "../components/Comelavoriamo";

const Homepage = () => {
    return(
        <div>
            <Slideshow/>
            <Chisiamo/>
            <Cosafacciamo/>
            <Comelavoriamo/>
            {/*<News/>*/}

        </div>
    )
}


export default Homepage;