// import {Route} from "react-router-dom";
// import Homepage from "./Homepage";

import piccoliEsercizi from '../assets/piccoliesercizicommerciali.jpg'

const PiccoliEserciziCommerciali = () => {
    return(
        <div className="mb-10">
            <div className={'bannerInternal flex flex-wrap justify-center items-center relative mb-10'}>
                <h2 className={'text-white absolute lg:text-68px text-[50px] m-auto text-center font-bold z-20'}>
                    Piccoli esercizi commerciali
                </h2>
                <div className={'bg-black absolute top-0 left-0 w-full h-full z-10 opacity-[0.2]'}></div>
                <img src={piccoliEsercizi} className={'w-full object-cover h-[350px]'} alt="piccoliEsercizi"/>

            </div>
            <div className={'text-container xl:px-40 px-10 '}>

            <p className={'mb-5'}>
                Ratio Solutions offre soluzioni per implementare nella propria attività servizi digitali, semplici ed immediati, costruiti sulla base delle esigenze e competenze del cliente, in grado di farti risparmiare tempo e risorse ed ampliare il tuo target di clientela.
            </p>
            <p className={'font-bold'}>Perché sceglierci</p>
            <ul>
                <li className={'mb-3'}>
                    - <span className={'font-bold'}>Soluzione personalizzata</span> studiata e sviluppata appositamente sulla base delle tue esigenze grazie al nostro percorso consulenziale che si realizza in 4 semplici passi;
                </li>
                <li className={'mb-3'}>
                    - <span className={'font-bold'}>Conquista nuovi clienti</span> grazie all’utilizzo di strategie di vendita multicanale sviluppando la tua strategia di vendita online (attraverso l’utilizzo di app già esistenti o lo sviluppo di una strategia studiata sulla base delle tue esigenze e competenze) conservando i punti di forza che rendono unico il tuo servizio in modo semplice e senza costi aggiuntivi;
                </li>
                <li className={'mb-3'}>
                    - <span className={'font-bold'}>Taglia i tempi inutili</span> attraverso l’utilizzo di semplici fogli di calcolo o app per semplificare la tua gestione in modo da avere sempre tutte le informazioni necessarie a portata di mano;
                </li>
            </ul>
                <br/>
                Principali aree d’intervento <br></br>
                Digitalizzazione di operazioni gestionali: semplifica la tua attività quotidiana grazie all’utilizzo di app o semplici fogli di calcolo che verranno realizzati sulla base delle tue esigenze e competenze che ti consentiranno di risparmiare tempo da poter utilizzare per altre attività<br></br>
                <br/>
                Strategie di vendita multicanale: raggiungi target di clientela differenti da quelli che raggiungi attualmente grazie allo sviluppo di apposite strategie strutturate in base alle tue specifiche esigenze e finalizzate all’integrazione di diversi canali di vendita (marketplace, social commerce, piattaforme e-commerce, soluzioni click and collect) o all’integrazione del proprio business in piattaforme e app online già esistenti<br></br>
                <br/>
                Marketing e comunicazione: ti aiutiamo a creare la giusta strategia di marketing per raccontare ciò che rende unica la tua attività <br></br>
            </div>
        </div>
    )
}

export default PiccoliEserciziCommerciali;