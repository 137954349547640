import piccoliEsercizi from "../assets/amministrazioni-pubbliche.jpg";

const AmministrazioniPubbliche = () => {
    return(
        <div className="mb-10">
            <div className={'bannerInternal flex flex-wrap justify-center items-center relative mb-10'}>
                <h2 className={'text-white absolute lg:text-68px text-[50px] m-auto text-center font-bold z-20'}>
                    Amministrazioni Pubbliche
                </h2>
                <div className={'bg-black absolute top-0 left-0 w-full h-full z-10 opacity-[0.2]'}></div>
                <img src={piccoliEsercizi} className={'w-full object-cover h-[350px]'} alt="piccoliEsercizi"/>

            </div>
            <div className={'text-container xl:px-40 px-10 '}>

                <p className={'mb-5'}>
                    Sfrutta i vantaggi che la digitalizzazione offre per offrire un supporto migliore ai tuoi cittadini e migliorare le performance dei tuoi uffici.
                </p>
                <p className={'font-bold mb-2'}>Perché sceglierci</p>
                <ul>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Semplifica la tua attività</span> grazie all’automatizzazione di alcune operazioni che svolgi quotidianamente, risparmiando tempo da dedicare ad altro;
                    </li>
                    <li className={'mb-3'}>
                        - <span className={'font-bold'}>Offri un servizio migliore ai tuoi cittadini</span> offrendo la possibilità di richiedere servizi in qualunque momento da ogni dispositivo informatico senza doversi recare direttamente nei vari uffici;
                    </li>
                </ul>
                <br/>
                <p className={'font-bold'}>Principali aree d’intervento</p> <br></br>
                Digitalizzazione di servizi: sviluppiamo soluzioni per la digitalizzazione dei servizi di comuni ed enti pubblici come sistemi di prenotazione degli spazi, sistemi di archiviazione, marketplace locale
                <br/>
                Corsi di formazione: offriamo corsi di alfabetizzazione digitale, comunicazione social e gamification rivolti a cittadini, titolari di esercizi commerciali e scuole.
                </div>
        </div>
    )
}

export default AmministrazioniPubbliche;